import { Component, OnInit } from '@angular/core';
import { OrderService } from '../../service/order.service';
import { Router } from '@angular/router';
import { Order } from '../../Models/Order';

@Component({
    templateUrl: './order.component.html'
})
export class OrderComponent implements OnInit {
    orders: Order[];
    status;
    globalFilterFields;
    orderStatus;

    constructor(private orderService: OrderService, private router: Router) {
        this.orders = [];
    }

    ngOnInit() {
        this.orderService.getAllOrders().subscribe( orders => {
            this.orders = orders['data'];
            console.log(this.orders);
        } );

        this.globalFilterFields = [
            'id',
            'user.first_name',
            'user.last_name',
            'user.organization',
            'status'
        ];

        this.orderStatus = [
            { label: 'Todos', value: null },
            { label: 'espera', value: 'Espera' },
            { label: 'validando', value: 'Validando' },
            { label: 'pagada', value: 'Pagada' },
            { label: 'cancelada', value: 'Cancelada' },
        ];
    }

    public selectCarWithButton(order: Order) {
        this.router.navigate(['orders', order.order]);
    }

}
