import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private API_URL = environment.STANDS_API;
  private baseUrl = this.API_URL;
  private userToken: string;

  userTkn: BehaviorSubject<string> = new BehaviorSubject<string>(this.getToken());

  constructor(private http: HttpClient) {}

  public register(registerInfo) {
    const authData = {
      email: registerInfo.email,
      first_name: registerInfo.first_name,
      last_name: registerInfo.last_name,
      password: registerInfo.password,
      confirm_password: registerInfo.confirm_password
    };
    return this.http.post( this.baseUrl + '/api/register', authData );
  }

  public login(loginInfo) {
    const authData = {
      email: loginInfo.email,
      password: loginInfo.password
    };
    return this.http.post(this.baseUrl + '/api/login', authData).pipe(map(resp => {
      this.setToken( resp );
      this.userTkn.next(this.userToken);
    }));
  }

  public refreshToken() {
    return this.http.post(this.baseUrl + '/api/login/refreshtoken', '').pipe(map(resp => {
      this.setToken( resp );
    }));
  }

  public logout() {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: 'Bearer' + ' ' + this.getToken(),
    });
    return this.http.post(this.baseUrl + '/api/logout', null, { headers }).subscribe( () => {
      this.forgetToken();
      this.userTkn.next(this.userToken);
    }, () => {
      this.forgetToken();
      this.userTkn.next(this.userToken);
    });
  }

  public getToken(): string {
    if (localStorage.getItem('standsApiTokenAdm')) {
      const tokenObj = JSON.parse(localStorage.getItem('standsApiTokenAdm'));
      this.userToken = tokenObj.access_token;
    } else {
      this.userToken = '';
    }
    return this.userToken;
  }

  public estaAutenticado(): boolean {
    const tokenObj = JSON.parse(localStorage.getItem('standsApiTokenAdm'));
    if (tokenObj && + new Date() > + tokenObj.expires_in) {
      return true;
    }
    return false;
  }

  private setToken(token) {
    this.userToken = token.access_token;
    token.expires_in = + new Date() + token.expires_in - 10;
    localStorage.setItem('standsApiTokenAdm', JSON.stringify(token));
  }

  private forgetToken() {
    localStorage.removeItem('standsApiTokenAdm');
    this.userToken = null;
  }

}
