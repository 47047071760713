import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FiscalService } from '../../service/fiscal.service';
import { Fiscal } from '../../Models/Fiscal';

@Component({
  selector: 'app-fiscal-show',
  templateUrl: './fiscal-show.component.html',
  styles: []
})
export class FiscalShowComponent implements OnInit {
  fiscal: Fiscal;
  regimenFiscal;
  formaPago;
  metodoPago;
  disabledButton: boolean;

  constructor(private route: ActivatedRoute, private fiscalService: FiscalService) {
    this.fiscal = new Fiscal('', '', '');
    this.regimenFiscal = [];
    this.formaPago = [];
    this.metodoPago = [];
    this.disabledButton = false;
  }

  ngOnInit() {
    this.route.params.subscribe( params => this.getFiscal(params['id']));
  }

  private getFiscal(id) {
    this.fiscalService.getFiscal(id).subscribe(data => {
      console.log(data['fiscal'])
      this.fiscal = data['fiscal'];
      this.regimenFiscal = data['regimenFiscal'];
      this.formaPago = data['formaPago'];
      this.metodoPago = data['metodoPago'];
    }, err => {
      console.error(err);
    });
  }

  updateFiscal(fiscalForm) {
    this.disabledButton = true;
    this.fiscalService.updateFiscal(this.fiscal.id, this.fiscal).subscribe( fiscal => {
      this.fiscal = fiscal['data'];
      this.disabledButton = false;
    }, err => {
      console.error(err);
      this.disabledButton = false;
    });
  }

}
