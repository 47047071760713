import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { StandComponent } from './demo/view/stand.component';
import { HomeComponent } from './demo/view/home.component';
import { UserComponent } from './demo/view/user/user.component';
import { OrderComponent } from './demo/view/order/order.component';
import { DashboardComponent } from './dashboard.component';
import { LoginComponent } from './login.component';
import { OrdersShowComponent } from './demo/view/order/orders-show.component';
import { UserShowComponent } from './demo/view/user/user-show.component';
import { AccountComponent } from './demo/view/account/account.component';
import { EspacioComponent } from './demo/view/espacio/espacio.component';
import { EspacioShowComponent } from './demo/view/espacio/espacio-show.component';
import { ExpositorComponent } from './demo/view/expositor/expositor.component';
import { RdonativoComponent } from './demo/view/rdonativo/rdonativo.component';
import { RdonativoShowComponent } from './demo/view/rdonativo/rdonativo-show.component';
import { FiscalComponent } from './demo/view/fiscal/fiscal.component';
import { FiscalShowComponent } from './demo/view/fiscal/fiscal-show.component';

// Guards
import { RoleGuard } from './demo/guard/role.guard';

export const routes: Routes = [
    {
        path: '',
        component: DashboardComponent,
        children: [
            { path: '', component: HomeComponent },
            { path: 'stands', component: StandComponent },
            { path: 'users', component: UserComponent },
            { path: 'users/:id', component: UserShowComponent },
            { path: 'account', component: AccountComponent },
            { path: 'espacios', component: EspacioComponent },
            { path: 'espacios/:id', component: EspacioShowComponent },
            { path: 'orders', component: OrderComponent },
            { path: 'orders/:order', component: OrdersShowComponent },
            { path: 'gafetes', component: ExpositorComponent },
            { path: 'recibodonativo', component: RdonativoComponent },
            { path: 'fiscal', component: FiscalComponent },
            { path: 'fiscal/:id', component: FiscalShowComponent },
            { path: 'recibodonativo/:id', component: RdonativoShowComponent },
        ],
        canActivate: [RoleGuard]
    },
    { path: 'login', component: LoginComponent },
];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes);
