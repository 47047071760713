import { Email } from './Email';

export class Fiscal {
    id: number;
    RFC: string;
    // tslint:disable-next-line: variable-name
    razon_social: string;
    moneda: string;
    emails: Email[];

    display: boolean;

    constructor( rfc: string, razonSocial: string, moneda: string ) {
        this.RFC = rfc;
        this.razon_social = razonSocial;
        this.moneda = moneda;
    }

}
