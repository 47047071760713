import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class OrderService {
  private API_URL = environment.STANDS_API;
  private baseUrl = this.API_URL + '/api/orders';
  private type = 'Bearer';

  constructor(private http: HttpClient, private auth: AuthService) { }

  public createAdminOrder(data) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: this.type + ' ' + this.auth.getToken(),
    });
    return this.http.post(this.baseUrl, data, { headers });
  }

  public getOrder(idx) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: this.type + ' ' + this.auth.getToken(),
    });
    return this.http.get(this.baseUrl + '/' + idx, { headers });
  }

  public getImage(id) {
    const headers = new HttpHeaders({
      Authorization: this.type + ' ' + this.auth.getToken(),
    });
    return this.http.get(this.baseUrl + '/' + id + '/edit', { headers });
  }

  public getAllOrders() {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: this.type + ' ' + this.auth.getToken(),
    });
    return this.http.get(this.baseUrl, { headers });
  }

  public getOpenOrder() {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: this.type + ' ' + this.auth.getToken(),
    });
    return this.http.get(this.baseUrl + '/create', { headers });
  }

  public updatePagoOrder(data, orderID) {
    const headers = new HttpHeaders({
      Authorization: this.type + ' ' + this.auth.getToken(),
    });
    return this.http.put(this.baseUrl + '/' + orderID, data, { headers });
  }

  public uploadVoucherImage(img, id, orderID) {
    const formData: FormData = new FormData();
    formData.append('voucher', img, img.name);
    formData.append('_method', 'PUT');
    formData.append('pago', id);
    const headers = new HttpHeaders({
      Authorization: this.type + ' ' + this.auth.getToken(),
    });
    return this.http.post(this.baseUrl + '/' + orderID, formData, { headers });
  }

  public cancelOrder(orderID) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: this.type + ' ' + this.auth.getToken(),
    });
    return this.http.delete(this.baseUrl + '/' + orderID, { headers });
  }

}
