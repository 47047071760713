import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PagoService } from '../../service/pago.service';
import { Pago } from '../../Models/Pago';

@Component({
  selector: 'app-rdonativo-show',
  templateUrl: './rdonativo-show.component.html',
  styles: []
})
export class RdonativoShowComponent implements OnInit {
  pago: Pago;

  constructor(private route: ActivatedRoute, private pagoService: PagoService) {
    this.pago = new Pago();
  }

  ngOnInit() {
    this.route.params.subscribe( params => this.getPago(params['id']));
    //getReciboDonativoById
  }

  private getPago(id) {
    this.pagoService.getReciboDonativoById(id).subscribe(pago => {
      this.pago = pago['data'];
      console.log(this.pago);
    }, err => {
      console.error(err);
      //this.dialog = {header: 'Error', message: 'No se pudo acceder al recurso.', display: true};
    });
  }

}
