import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';

@Injectable()
export class ResponsivaService {
  private API_URL = environment.STANDS_API;
  private baseUrl = this.API_URL + '/api/responsivas';
  private type = 'Bearer';

  constructor( private http: HttpClient, private auth: AuthService ) { }

  public getAllResponsivas() {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: this.type + ' ' + this.auth.getToken(),
    });
    return this.http.get(this.baseUrl, { headers });
  }

  public getResponsiva( responsivaID ) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: this.type + ' ' + this.auth.getToken(),
    });
    return this.http.get(this.baseUrl + '/' + responsivaID + '/edit', { headers });
  }
}
