import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';

@Injectable()
export class MethodService {
  private API_URL = environment.STANDS_API;
  private baseUrl = this.API_URL + '/api/methods';
  private type = 'Bearer';

  constructor(private httpClient: HttpClient, private authService: AuthService) { }

  getMethods() {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: this.type + ' ' + this.authService.getToken(),
    });
    return this.httpClient.get(this.baseUrl, { headers });
  }

}
