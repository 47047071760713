import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../service/user.service';
import { User } from '../../Models/User.model';

@Component({
  selector: 'app-user-show',
  templateUrl: './user-show.component.html',
  styles: []
})
export class UserShowComponent implements OnInit {
  user: User;
  clonedUser: User;
  message;
  DisabledEditUserBtn: boolean;

  constructor(private route: ActivatedRoute, private userService: UserService ) {
    this.user = new User('', '', '', '');
    this.message = {display: false};
    this.DisabledEditUserBtn = false;
  }

  ngOnInit() {
    this.route.params.subscribe( params => {
      this.userService.getOneUser(params['id']).subscribe( data => {
        this.user = data['data'];
        console.log(this.user);
      } );
    });
  }

  public editUser() {
    this.clonedUser = {...this.user};
    this.user.editing = true;
  }

  public cancelEditUser() {
    this.user = {...this.clonedUser};
  }

  public updateUser() {
    if (!this.userHasDifferentValues() && !this.hasNewPassword()) {
      this.message = {header: 'Sin cambios', message: 'No has realizado ningún cambio.', display: true};
      return;
    }
    if (!this.isPasswordConfirmed()) {
      this.message = {header: 'Contraseñas', message: 'Las contraseñas no coinciden.', display: true};
      return;
    }
    this.DisabledEditUserBtn = true;
    this.userService.updateUser(this.user).subscribe(user => {
      this.DisabledEditUserBtn = false;
      this.user = user['data'];
      delete(this.clonedUser);
      this.message = {header: 'Éxito', message: 'Has modificado los datos del usuario.', display: true};
    }, err => {
      this.DisabledEditUserBtn = false;
      this.message = {header: 'Error', message: 'No se pudo realizar la operación.', display: true};
      console.error(err);
    });
  }

  private userHasDifferentValues(): boolean {
    if (this.user.first_name !== this.clonedUser.first_name ||
      this.user.last_name !== this.clonedUser.last_name ||
      this.user.email !== this.clonedUser.email ||
      this.user.organization !== this.clonedUser.organization) {
      return true;
    }
    return false;
  }

  private hasNewPassword(): boolean {
    if (this.user.password && this.user.password.length > 0) {
      return true;
    }
    return false;
  }

  private isPasswordConfirmed(): boolean {
    if (this.hasNewPassword && this.user.password === this.user.password_confirmation) {
      return true;
    }
    return false;
  }

}
