import { Component, OnInit } from '@angular/core';
import { FiscalService } from '../../service/fiscal.service';
import { Router } from '@angular/router';
import { Fiscal } from '../../Models/Fiscal';

@Component({
  selector: 'app-fiscal',
  templateUrl: './fiscal.component.html',
  styles: []
})
export class FiscalComponent implements OnInit {
  globalFilterFields: any[];
  fiscals: Fiscal[];
  pdf: any[];

  constructor(private fiscalService: FiscalService, private router: Router) {
    this.fiscals = [];
    this.globalFilterFields = [
      'user.first_name',
      'user.last_name',
      'RFC',
      'email',
      ];
    this.pdf = [{label: 'Todos', value: null}, {label: 'Si', value: 1}, {label: 'No', value: 0}];
  }

  ngOnInit() {
    this.fiscalService.getAllFiscals().subscribe(fiscals => {
      fiscals['data'].forEach(fiscal => {
        if (fiscal.pdf_file) {
          fiscal.pdf_file = 1;
        } else {
          fiscal.pdf_file = 0;
        }
      });
      this.fiscals = fiscals['data'];
    }, err => {
      console.error(err);
    });
  }

  showFiscal(id) {
    this.router.navigate(['fiscal', id]);
  }

}
