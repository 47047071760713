import { Method } from './Method';
import { Fiscal } from './Fiscal';
import { Order } from './Order';

export class Pago {
    id: number;
    pago: number;
    voucher: string;
    status: string;
    notes: string;
    method: Method;
    fiscal: Fiscal;
    order: Order;
    fecha_pago: Date;
    nrecibo: string;
    noReciboEditable: boolean;
    uploadingRecibo: boolean;
    xml: string;
    folio_voucher: string;

    pagoEdit: boolean;
    pagoAux: number;
}
