import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { Stand } from '../Models/Stand';

@Injectable({
  providedIn: 'root'
})
export class StandService {
  private API_URL = environment.STANDS_API;
  private baseUrl = this.API_URL + '/api/stands';
  private type = 'Bearer';

  constructor(private http: HttpClient, private auth: AuthService) { }

  getStands() {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: this.type + ' ' + this.auth.getToken(),
    });
    return this.http.get(this.baseUrl);
  }

}
