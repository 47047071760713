import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from './auth.service';

@Injectable()
export class PagoService {
  private API_URL = environment.STANDS_API;
  private baseUrl = this.API_URL + '/api/pagos';
  private type = 'Bearer';

  constructor(private http: HttpClient, private authService: AuthService) { }

  public getImage(pagoID: number) {
    const headers = new HttpHeaders({
      Authorization: this.type + ' ' + this.authService.getToken(),
    });
    return this.http.get(this.baseUrl + '/' + pagoID + '/edit', { headers });
  }

  public uploadRecibo(recibo, pagoID) {
    const formData: FormData = new FormData();
    formData.append('recibo', recibo, recibo.name);
    formData.append('_method', 'PUT');
    const headers = new HttpHeaders({
      Authorization: this.type + ' ' + this.authService.getToken(),
    });
    return this.http.post(this.baseUrl + '/' + pagoID, formData, { headers });
  }

  public uploadXML(xml, pagoID) {
    const formData: FormData = new FormData();
    formData.append('xml', xml, xml.name);
    formData.append('_method', 'PUT');
    const headers = new HttpHeaders({
      Authorization: this.type + ' ' + this.authService.getToken(),
    });
    return this.http.post(this.baseUrl + '/' + pagoID, formData, { headers });
  }

  public getRecibo(pagoID) {
    const headers = new HttpHeaders({
      Authorization: this.type + ' ' + this.authService.getToken(),
    });
    const params = new HttpParams().set('pago', pagoID);
    return this.http.get(this.baseUrl + '/create', { headers, params, responseType: 'blob' });
  }

  public getXML(pagoID) {
    const headers = new HttpHeaders({
      Authorization: this.type + ' ' + this.authService.getToken(),
    });
    const params = new HttpParams({fromObject: {pago: pagoID, xml: 'xml'}});
    return this.http.get(this.baseUrl + '/create', { headers, params, responseType: 'blob' });
  }

  public setNumeroRecibo(nrecibo, pagoID) {
    const headers = new HttpHeaders({
      Authorization: this.type + ' ' + this.authService.getToken(),
    });
    return this.http.put(this.baseUrl + '/' + pagoID, {noRecibo: nrecibo}, { headers });
  }

  public newPago(data) {
    const headers = new HttpHeaders({
      Authorization: this.type + ' ' + this.authService.getToken(),
    });
    return this.http.post(this.baseUrl, data, { headers });
  }

  public updateFechaPago(fechaPago, pagoID) {
    const headers = new HttpHeaders({
      Authorization: this.type + ' ' + this.authService.getToken(),
    });
    return this.http.put(this.baseUrl + '/' + pagoID, {fechaPago: fechaPago}, { headers });
  }

  public updateMontoPago(monto, pagoID) {
    const headers = new HttpHeaders({
      Authorization: this.type + ' ' + this.authService.getToken(),
    });
    return this.http.put(this.baseUrl + '/' + pagoID, {editPago: monto}, { headers });
  }

  public getRecibosDonativo() {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: this.type + ' ' + this.authService.getToken(),
    });
    return this.http.get(this.baseUrl, { headers });
  }

  public getReciboDonativoById(pagoId) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: this.type + ' ' + this.authService.getToken(),
    });
    return this.http.get(this.baseUrl + '/' + pagoId , { headers });
  }

  public updateFolio(pago) {
    const headers = new HttpHeaders({
      Authorization: this.type + ' ' + this.authService.getToken(),
    });
    return this.http.put(this.baseUrl + '/' + pago.id, {folioVoucher: pago.folio_voucher}, { headers });
  }

}
