import { Component, OnInit } from '@angular/core';
import { Expositor } from '../../Models/Expositor';
import { ExpositorService } from '../../service/expositor.service';

@Component({
  selector: 'app-expositor',
  templateUrl: './expositor.component.html',
  styles: []
})
export class ExpositorComponent implements OnInit {
  expositores: Expositor[];
  globalFilterFields: any[];

  constructor( private expositorService: ExpositorService ) {
    this.expositores = [];
  }

  ngOnInit() {
    this.expositorService.getAllExpositors().subscribe( expositores => {
      this.expositores = expositores['data'];
      console.log(this.expositores);
    }, err => {
      console.error(err);
    });

    this.globalFilterFields = [
      'order.id',
      'empresa',
      'name',
    ];
  }

}
