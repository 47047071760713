import { Component, OnInit } from '@angular/core';
import { PagoService } from '../../service/pago.service';
import { Router } from '@angular/router';
import { Pago } from '../../Models/Pago';

@Component({
  templateUrl: './rdonativo.component.html',
})
export class RdonativoComponent implements OnInit {
  globalFilterFields: any[];
  pagos: Pago[];
  pagoStatus: any[];
  rdonativoStatus: any[];
  rdononativoAnticipado: any[];

  constructor(private pagoService: PagoService, private router: Router) {
    this.pagos = [];
    this.globalFilterFields = [
      'order.id',
      'fiscal.user.organization',
      'fiscal.user.first_name',
      'fiscal.user.last_name'
      ];
    this.pagoStatus = [
      {label: 'Todos', value: null},
      {label: 'Espera', value: 'espera'},
      {label: 'Validando', value: 'validando'},
      {label: 'Rechazado', value: 'rechazado'},
      {label: 'Pagado', value: 'pagado'},
      {label: 'Cancelado', value: 'cancelado'}
      ];
    this.rdonativoStatus = [{label: 'Todos', value: null}, {label: 'Si', value: 1}, {label: 'No', value: 0}];
    this.rdononativoAnticipado = [{label: 'Todos', value: null}, {label: 'Si', value: 1}, {label: 'No', value: 0}];
  }

  ngOnInit() {
    this.pagoService.getRecibosDonativo().subscribe(pagos => {
      this.pagos = pagos['data'];
    }, err => {
      console.error(err);
    });
  }

  showPago(pagoId) {
    this.router.navigate(['recibodonativo', pagoId]);
  }

}
