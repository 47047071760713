import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutes } from './app.routes';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { AppComponent } from './app.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppSideBarComponent } from './app.sidebar.component';
import { LoginComponent } from './login.component';
import { DashboardComponent } from './dashboard.component';
import { AccountComponent } from './demo/view/account/account.component';
import { EspacioComponent } from './demo/view/espacio/espacio.component';

import { HomeComponent } from './demo/view/home.component';
import { StandComponent } from './demo/view/stand.component';
import { UserComponent } from './demo/view/user/user.component';
import { OrderComponent } from './demo/view/order/order.component';
import { OrdersShowComponent } from './demo/view/order/orders-show.component';
import { UserShowComponent } from './demo/view/user/user-show.component';

import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { GrowlModule } from 'primeng/growl';
// import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { ScheduleModule } from 'primeng/schedule';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';

import { AuthInterceptorService } from './demo/service/auth-interceptor.service';
import { RoleGuard } from './demo/guard/role.guard';
import { UserService } from './demo/service/user.service';
import { ConfirmationService } from 'primeng/api';
import { FiscalService } from './demo/service/fiscal.service';
import { OrderService } from './demo/service/order.service';
import { MethodService } from './demo/service/method.service';
import { PagoService } from './demo/service/pago.service';
import { ResponsivaService } from './demo/service/responsiva.service';
import { EspacioShowComponent } from './demo/view/espacio/espacio-show.component';
import { ExpositorComponent } from './demo/view/expositor/expositor.component';
import { ExpositorService } from './demo/service/expositor.service';
import { RdonativoComponent } from './demo/view/rdonativo/rdonativo.component';
import { RdonativoShowComponent } from './demo/view/rdonativo/rdonativo-show.component';
import { FiscalComponent } from './demo/view/fiscal/fiscal.component';
import { FiscalShowComponent } from './demo/view/fiscal/fiscal-show.component';

@NgModule({
    declarations: [
        AppComponent,
        AppTopBarComponent,
        AppSideBarComponent,
        HomeComponent,
        StandComponent,
        UserComponent,
        OrderComponent,
        LoginComponent,
        DashboardComponent,
        OrdersShowComponent,
        UserShowComponent,
        AccountComponent,
        EspacioComponent,
        EspacioShowComponent,
        ExpositorComponent,
        RdonativoComponent,
        RdonativoShowComponent,
        FiscalComponent,
        FiscalShowComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutes,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CarouselModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DropdownModule,
        EditorModule,
        FieldsetModule,
        FileUploadModule,
        GalleriaModule,
        GrowlModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        ScheduleModule,
        SelectButtonModule,
        SlideMenuModule,
        SliderModule,
        SpinnerModule,
        SplitButtonModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true },
        RoleGuard, UserService,
        ConfirmationService, FiscalService, OrderService, MethodService, PagoService, ResponsivaService, ExpositorService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
