import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(private auth: AuthService, private router: Router) { }

  intercept( request: HttpRequest<any>, next: HttpHandler ): Observable<any> {
    return next.handle(request).pipe(catchError( error => {
      // We don't want to refresh token for some requests like login or refresh token itself
      // So we verify url and we throw an error if it's the case
      if ( request.url.includes('refreshtoken') || request.url.includes('login') ) {
        // We do another check to see if refresh token failed
        // In this case we want to logout user and to redirect it to login page

        if (request.url.includes('refreshtoken')) {
          this.auth.logout();
        }

        return throwError(error);
      }

      // If error status is different than 401 we want to skip refresh token
      // So we check that and throw the error if it's the case
      if (error.status !== 401) {
        return throwError(error);
      }

      if (this.refreshTokenInProgress) {
        // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
        // – which means the new token is ready and we can retry the request again
        return this.refreshTokenSubject
          .pipe(filter(result => result !== null))
          .pipe(take(1))
          .pipe(switchMap(() => next.handle(this.addAuthenticationToken(request))));
      } else {
        this.refreshTokenInProgress = true;

        // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
        this.refreshTokenSubject.next(null);

        // Call auth.refreshAccessToken(this is an Observable that will be returned)
        return this.auth.refreshToken().pipe(
          switchMap( (token: any) => {
            this.refreshTokenInProgress = false;
            this.refreshTokenSubject.next(token);
            return next.handle(this.addAuthenticationToken(request));
        }), catchError( (err: any) => {
          this.refreshTokenInProgress = false;
          // this.auth.logout();
          this.router.navigate(['/login']);
          return throwError(error);
        }));
      }
    }));
  }

  addAuthenticationToken(request) {
    // Get access token from Local Storage
    const accessToken = this.auth.getToken();

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken) {
        return request;
    }

    // We clone the request, because the original request is immutable
    return request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + accessToken
      }
    });
  }

}
