import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../Models/User.model';

@Injectable()
export class UserService {
  private API_URL = environment.STANDS_API;
  private baseUrl = this.API_URL + '/api/users';
  private type = 'Bearer';

  constructor( private http: HttpClient, private auth: AuthService ) { }

  public getUsers() {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: this.type + ' ' + this.auth.getToken(),
    });
    return this.http.get(this.baseUrl, { headers });
  }

  public getOneUser(idx) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: this.type + ' ' + this.auth.getToken(),
    });
    return this.http.get(this.baseUrl + '/' + idx, { headers });
  }

  public deleteUser(idx) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: this.type + ' ' + this.auth.getToken(),
    });
    return this.http.delete(this.baseUrl + '/' + idx, { headers });
  }

  public createUser(user: User) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: this.type + ' ' + this.auth.getToken(),
    });
    return this.http.post(this.baseUrl, user , { headers });
  }

  public getUser() {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: this.type + ' ' + this.auth.getToken(),
    });
    return this.http.get(this.baseUrl + '/' + 1 + '/edit', {headers});
  }

  public updateUser(user) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: this.type + ' ' + this.auth.getToken(),
    });
    return this.http.put(this.baseUrl + '/' + user.id, user, {headers});
  }

  public updateAdminAcount(user) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: this.type + ' ' + this.auth.getToken(),
    });
    return this.http.put(this.baseUrl + '/' + user.id, user, {headers});
  }

}
