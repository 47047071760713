export class User {
    id: number;
    email: string;
    // tslint:disable-next-line: variable-name
    first_name: string;
    // tslint:disable-next-line: variable-name
    last_name: string;
    organization: string;
    active: boolean;
    created_at: Date;
    updated_at: Date;

    password: string;
    password_confirmation: string;
    passwordActual: string;

    editing: boolean;

    correo: string;
    correo_confirmation: string;

    public constructor(email: string, first_name: string, last_name: string, organization: string) {
        this.email = email;
        this.first_name = first_name;
        this.last_name = last_name;
        this.organization = organization;
    }

}
