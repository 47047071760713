import { Component } from '@angular/core';
import { AuthService } from './demo/service/auth.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: []
})
export class LoginComponent {
  email = '';
  password = '';
  disabledButton = false;
  message;

  constructor(private authservice: AuthService, private router: Router) {
    this.message = {display: false};
  }

  onSubmit(form: NgForm) {
    this.disabledButton = true;
    this.authservice.login({email: this.email, password: this.password}).subscribe( resp => {
      this.disabledButton = false;
      this.router.navigateByUrl('/');
    }, err => {
      if (err.status === 400) {
        this.message  = {
          header: 'Error',
          message: 'Datos de inicio de sesión incorrectos.',
          display: true
        };
      }
      form.reset();
      this.disabledButton = false;
    });
  }

}
