import { Component, OnInit } from '@angular/core';
import { UserService } from '../../service/user.service';
import { User } from '../../Models/User.model';
import { NgForm } from '@angular/forms';

@Component({
    templateUrl: './user.component.html'
})
export class UserComponent implements OnInit {
    users: [];
    user: User;
    cols: any[];
    checkCreandoUsuario: boolean;

    constructor( private userService: UserService) {
        this.checkCreandoUsuario = false;
        this.user = new User('', '', '', '');
    }

    ngOnInit(): void {
        this.getUsers();
        this.cols = [
            { field: 'first_name', header: 'Nombre', width: '20%' },
            { field: 'last_name', header: 'Apellido', width: '20%' },
            { field: 'organization', header: 'Organización', width: '20%' },
            { field: 'email', header: 'Email', width: '40%' }
        ];
    }

    private getUsers() {
        this.userService.getUsers().subscribe(resp => {
            this.users = resp['data'];
        }, err => {
            console.log(err);
        });
    }

    public newFakeUser(form: NgForm) {
        this.checkCreandoUsuario = true;
        this.userService.createUser(this.user).subscribe( user => {
            this.clearUser();
            this.getUsers();
            form.reset();
            this.checkCreandoUsuario = false;
        }, err => {
            console.log( err );
            form.reset();
            this.checkCreandoUsuario = false;
        } );
    }

    private clearUser() {
        this.user.first_name = '';
        this.user.last_name = '';
        this.user.email = '';
        this.user.organization = '';
    }

}
