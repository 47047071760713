import { Component, OnInit } from '@angular/core';
import { StandService } from '../service/stand.service';
import { MethodService } from '../service/method.service';
import { Stand } from '../Models/Stand';
import { Method } from '../Models/Method';
import { UserService } from '../service/user.service';
import { User } from '../Models/User.model';
import { OrderService } from '../service/order.service';

@Component({
    templateUrl: './stand.component.html',
    styleUrls: ['./stand.component.css'],
})
export class StandComponent implements OnInit {
    stands: Stand[];
    selectedStands: string[] = [];
    selectedPago: string;
    errors = {};
    formulario = false;
    factura: string;
    moneda: string;
    payMethods: Method[];
    users: User[];
    user: User;
    userFilter: User[];
    selectedUser: User;
    tipoPago: string;
    total: number;
    disabledButton: boolean;
    dialogHeader: string;
    dialogDisplay: boolean;
    dialogMessage: string;

    constructor(
        private standService: StandService,
        private methodService: MethodService,
        private userService: UserService,
        private orderService: OrderService
    ) {
        this.stands = [];
        this.payMethods = [];
        this.users = [];
        this.userFilter = [];
        this.user = new User('', '', '', '');
        this.selectedUser = new User('', '', '', '');
        for (let i = 0; i < 124; i++) {
            this.stands.push(new Stand(i + 1, ''));
        }
        this.total = 0;
        this.disabledButton = false;
        this.dialogDisplay = false;
    }

    ngOnInit() {
        this.getStands();
        this.getPayMethod();
        this.getAllUsers();
    }

    private getStands() {
        this.standService.getStands().subscribe(resp => {
            this.stands = resp['data'];
        }, err => {
            console.error('No se pudo acceder al recurso');
        });
    }

    private getPayMethod() {
        this.methodService.getMethods().subscribe(data => {
            // tslint:disable-next-line: no-string-literal
            this.payMethods = data['data'];
        });
    }

    public getAllUsers() {
        this.userService.getUsers().subscribe(users => {
            this.users = users['data'];
        }, err => console.log(err));
    }

    calculaTotal() {
        let total = 0;
        for (const stand of this.stands) {
            if (stand.estado === 's') {
                total += +stand.price;
            }
        }
        this.total = total;
    }

    public markStand(stand) {
        if (this.formulario) {
            if (stand.estado === 'l') {
                stand.estado = 's';
            } else
                if (stand.estado === 's') {
                    stand.estado = 'l';
                }
        }
        this.selectedStandsToPay();
        this.calculaTotal();
    }

    private selectedStandsToPay() {
        const standsIDs = [];
        for (const stand of this.stands) {
            if (stand.estado === 's') {
                standsIDs.push(stand.id);
            }
        }
        this.selectedStands = standsIDs;
    }

    public filterUsers(event): void {
        this.userFilter = [];
        for (let i = 0; i < this.users.length; i++) {
            const usuario = this.users[i];
            if (usuario.email.toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
                this.userFilter.push(usuario);
            }
        }
    }

    public seleccionaUsuario(usuario: User): void {
        this.selectedUser = usuario;
    }

    public generateUserOrder() {
        this.orderService.createAdminOrder({
            stands: this.selectedStands,
            method: this.selectedPago,
            pago: this.tipoPago,
            avatar_id: this.selectedUser.id
        }).subscribe(order => {
            console.log(order);
        }, err => console.log(err));
    }

    onSubmit() {
        this.disabledButton = true;
        this.selectedStandsToPay();
        if (this.selectedStands.length < 1) {
            this.generateDialogNotify({
                header: 'Formulario',
                message: 'No has seleccionado stands para iniciar la orden.'
            });
            this.disabledButton = false;
            return;
        }
        const infoRequest = this.createDataOrderRequest();
        this.orderService.createAdminOrder(infoRequest).subscribe(order => {
            this.orderCreateSuccess(order);
        }, err => {
            this.orderCreateError(err);
        });
    }

    private generateDialogNotify(conf) {
        this.dialogHeader = conf.header;
        this.dialogMessage = conf.message;
        this.dialogDisplay = true;
    }

    private orderCreateSuccess(data) {
        this.disabledButton = false;
        this.generateDialogNotify({
            header: 'Orden creada',
            message: 'Has creado una nueva orden para ' + this.selectedUser.first_name + ' ' + this.selectedUser.last_name
        });
        this.getStands();
    }

    private orderCreateError(error) {
        console.log(error);
        this.errors = error.error.error;
        this.disabledButton = false;
        if (error.status === 422) {
            this.generateDialogNotify({
                header: 'Formulario error',
                message: 'Los datos del formulario no son correctos.'
            });
        } else {
            this.generateDialogNotify({
                header: 'Error',
                message: 'Ha ocurrido un error en el servidor. ' + error.error.message
            });
        }
    }

    private createDataOrderRequest() {
        return {
            stands: this.selectedStands,
            method: this.selectedPago,
            factura: this.factura,
            moneda: this.moneda,
            avatar_id: this.selectedUser.id
        };
    }

}
