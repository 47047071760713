import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class FiscalService {
  private API_URL = environment.STANDS_API;
  private baseUrl = this.API_URL + '/api/fiscals';
  private type = 'Bearer';

  constructor(private http: HttpClient, private auth: AuthService) { }

  updateFiscal(id, obj) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: this.type + ' ' + this.auth.getToken(),
    });
    return this.http.put(this.baseUrl + '/' + id, obj, { headers });
  }

  getAllFiscals() {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: this.type + ' ' + this.auth.getToken(),
    });
    return this.http.get(this.baseUrl, { headers });
  }

  getFiscal(id) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: this.type + ' ' + this.auth.getToken(),
    });
    return this.http.get(this.baseUrl + '/' + id + '/edit', { headers });
  }

}
