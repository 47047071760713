import { Stand } from './Stand';
import { Pago } from './Pago';
import { User } from './User.model';

export class Order {
    public id: number;
    public order: string;
    public status: string;
    public total: number;
    public stands: Stand[];
    public pagos: Pago[];
    public user: User;
    public dollar: number;
    public facturado: boolean;

    public moneda: string;
    public pagado: number;
    public resto: number;
}
