export class Responsiva {
    terminada: boolean;
    modificable: boolean;
    octanorm: boolean;
    mampara: boolean;
    antepecho: boolean;
    lampara: boolean;
    tablon: boolean;
    mantel: boolean;
    sillas: boolean;
    // tslint:disable-next-line: variable-name
    montaje_pared_trasera: boolean;
    // tslint:disable-next-line: variable-name
    montaje_laterales_antepecho: boolean;
    // tslint:disable-next-line: variable-name
    montaje_lampara: boolean;
    // tslint:disable-next-line: variable-name
    nombre_antepecho: string;
    // tslint:disable-next-line: variable-name
    empresa_montaje: string;
    contacto: string;
    email: string;
    telefono: string;
    electronicos: string;
    empresa: string;
    responsable: string;
    celular: string;
    archivo: boolean;
}
