import { Component, OnInit } from '@angular/core';
import { Responsiva } from '../../Models/Responsiva';
import { ResponsivaService } from '../../service/responsiva.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-espacio',
  templateUrl: './espacio.component.html',
  styles: []
})
export class EspacioComponent implements OnInit {
  responsivas: Responsiva[];
  globalFilterFields: any[];
  octanorm;

  constructor(private responsivaService: ResponsivaService, private router: Router) {
    this.responsivas = [];
  }

  ngOnInit() {
    this.responsivaService.getAllResponsivas().subscribe( responsivas => {
      this.responsivas = responsivas['data'];
      console.log(this.responsivas);
    }, err => {
      console.error(err);
    });
    this.globalFilterFields = [
      'order.id',
      'empresa',
      'responsable',
      'celular',
      'octanorm'
    ];
    this.octanorm = [
      { label: 'Todos', value: null },
      { label: 'Si', value: 1 },
      { label: 'No', value: 0 },
    ];
  }

  public showResponsiva( responsivaID ) {
    this.router.navigate(['/espacios', responsivaID]);
  }

}
