import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import decode from 'jwt-decode';

@Injectable()
export class RoleGuard implements CanActivate {

  constructor( private router: Router, private auth: AuthService) {}

  canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const token = this.auth.getToken();
    let isAdmin = '';
    if (token) {
      const tokenPayload = decode(token);
      isAdmin = tokenPayload.scopes.find( (element) => element === 'admin' );
    }
    if ( !token || isAdmin !== 'admin' ) {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
}
