import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from '../../service/order.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Order } from '../../Models/Order';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Pago } from '../../Models/Pago';
import { PagoService } from '../../service/pago.service';
import { Fiscal } from '../../Models/Fiscal';
import { ConfirmationService } from 'primeng/api';
import { MethodService } from '../../service/method.service';
import { Method } from '../../Models/Method';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-orders-show',
    templateUrl: './orders-show.component.html',
    styles: ['.clase{ border: 2px solid red;width: 300px;background-color: antiquewhite;}'],
    animations: [
        trigger('rowExpansionTrigger', [
            state('void', style({
                transform: 'translateX(-10%)',
                opacity: 0
            })),
            state('active', style({
                transform: 'translateX(0)',
                opacity: 1
            })),
            transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class OrdersShowComponent implements OnInit {
    order: Order;
    image;
    datos = false;
    images: object;
    pagos: Pago[];
    cols: any[];
    lightBoxImages;
    voucherImg: File;
    displayDialog: boolean;
    dialogType;
    monto: number;
    observacion: string;
    actionPagoID: string;
    dialog;
    editNRecibo: boolean;
    fiscalSelected: Fiscal;
    visibleUploadFile: boolean;
    pagoID;
    visibleModalNewPago: boolean;
    payMethods: Method[];
    selectedPago: string;
    fechaPago: Date;
    es: any;
    visibleModalFechaPago: boolean;
    sendingValidation: boolean;
    sendingPayDate: boolean;
    disabledMontoBtn: boolean;
    disabledNoReciboBtn: boolean;
    disabledCreatePagoBtn: boolean;
    uploadingXML: boolean;
    disableFolioBtn: boolean;

    constructor(
        private route: ActivatedRoute,
        private orderService: OrderService,
        private pagoService: PagoService,
        private sanitizer: DomSanitizer,
        private confirmationService: ConfirmationService,
        private methodService: MethodService) {
        this.monto = 0;
        this.actionPagoID = '';
        this.observacion = '';
        this.displayDialog = false;
        this.dialogType = '';
        this.order = new Order();
        this.pagos = [];
        this.images = {};
        this.lightBoxImages = {};
        this.dialog = {};
        this.editNRecibo = false;
        this.visibleUploadFile = false;
        this.visibleModalNewPago = false;
        this.visibleModalFechaPago = false;
        this.fiscalSelected = new Fiscal('', '', '');
        this.sendingValidation = false;
        this.sendingPayDate = false;
        this.disabledMontoBtn = false;
        this.disabledNoReciboBtn = false;
        this.disabledCreatePagoBtn = false;
        this.uploadingXML = false;
        this.disableFolioBtn = false;
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.getOrder(params['order']);
        });
        this.getPayMethod();
        this.cols = [
            { field: 'pago', header: 'Pago' },
            { field: 'method.method', header: 'Método' },
            { field: 'status', header: 'Estado' }
        ];

        this.es = {
            firstDayOfWeek: 1,
            dayNames: [ 'domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado' ],
            dayNamesShort: [ 'dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb' ],
            dayNamesMin: [ 'D', 'L', 'M', 'X', 'J', 'V', 'S' ],
            monthNames: [ 'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre' ],
            monthNamesShort: [ 'ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic' ],
            today: 'Hoy',
            clear: 'Borrar'
        };
    }

    private getOrder(orderHash): void {
        this.orderService.getOrder(orderHash).subscribe(orden => {
            this.order = orden['data'];
            this.complementOrder();
            this.pagos = this.order.pagos;
            this.datos = true;
            console.log(this.order);
        }, err => {
            console.error(err);
            this.dialog = {header: 'Error', message: 'No se pudo acceder al recurso.', display: true};
        });
    }

    public validarPago(pagoID: number, accion: string): void {
        this.orderService.updatePagoOrder({ status: accion, observacion: '', pago: pagoID }, this.order.id).subscribe(resp => {
            this.order = resp['data'];
            this.complementOrder();
            this.pagos = this.order.pagos;
            this.dialog({
                header: 'Pago validado',
                message: 'La operación se realizó con éxito.'
            });
        }, err => {
            console.error(err);
            this.dialog = {header: 'Error', message: 'No se pudo realizar la operación.', display: true};
        });
    }

    public loadImages(id: string): void {
        if (!this.images[id] && this.pagoHasVoucher(id)) {
            this.pagoService.getImage(+id).subscribe(baseImage => {
                const objectURL = 'data:image/jpeg;base64,' + baseImage['image'];
                this.images[id] = this.sanitizer.bypassSecurityTrustUrl(objectURL);
                this.imageToLinghtBox(id);
            }, err => {
                console.error(err);
            });
        }
    }

    private imageToLinghtBox(id): void {
        this.lightBoxImages[id] = [
            {
                source: this.images[id],
                thumbnail: this.images[id],
                title: 'Voucher'
            }
        ];
    }

    private pagoHasVoucher(id): boolean {
        for (const pago of this.order.pagos) {
            if (pago.id === id && pago.voucher) {
                return true;
            }
        }
        return false;
    }

    public onUpload(event, image) {
        for (const file of event.files) {
            this.voucherImg = file;
        }
        if (this.pagoID) {
            this.orderService.uploadVoucherImage(this.voucherImg, this.pagoID, this.order.id).subscribe(order => {
                // tslint:disable-next-line: no-string-literal
                this.order = order['data'];
                this.complementOrder();
                this.voucherImg = null;
                this.images[this.pagoID] = '';
                this.pagos = this.order.pagos;
                this.replaceImage('' + this.pagoID);
                this.cancelUploadFile(image);
            }, err => {
                console.error(err);
                this.dialog = { header: 'Error', message: 'No se pudo realizar la operación.', display: true };
            });
        }
    }

    private replaceImage(id: string): void {
        this.pagoService.getImage(+id).subscribe(baseImage => {
            const objectURL = 'data:image/jpeg;base64,' + baseImage['image'];
            this.images[id] = this.sanitizer.bypassSecurityTrustUrl(objectURL);
            this.imageToLinghtBox(id);
        }, err => {
            console.error(err);
        });
    }

    public showDialog(tipo: string, pagoID: string) {
        this.dialogType = tipo;
        this.actionPagoID = pagoID;
        this.displayDialog = true;
    }

    public cancelarValidacion() {
        this.displayDialog = false;
        this.clearRequestVariables();
    }

    public enviarValidacion() {
        this.sendingValidation = true;
        this.orderService.updatePagoOrder({
            status: this.dialogType,
            observacion: this.observacion,
            pago: this.actionPagoID,
            monto: this.monto
        },
            this.order.id).subscribe(resp => {
                this.sendingValidation = false;
                this.disabledMontoBtn = false;
                this.order = resp['data'];
                this.complementOrder();
                this.pagos = this.order.pagos;
                this.clearRequestVariables();
                this.displayDialog = false;
                console.log(this.order);
            }, err => {
                this.sendingValidation = false;
                this.disabledMontoBtn = false;
                this.clearRequestVariables();
                this.displayDialog = false;
                console.log(this.order);
            });
    }

    private clearRequestVariables() {
        this.dialogType = '';
        this.observacion = '';
        this.monto = 0;
    }

    public uploadRecibo(event, pago, form) {
        pago.uploadingRecibo = true;
        this.pagoService.uploadRecibo(event.files[0], pago.id).subscribe(resp => {
            this.changePagoOnOrder(pago.id, resp['data'], 'recibo');
            form.clear();
            console.log(this.order);
            pago.uploadingRecibo = false;
        }, err => {
            console.error(err);
            pago.uploadingRecibo = false;
        });
    }

    public uploadXML(event, pago, form) {
        this.uploadingXML = true;
        this.pagoService.uploadXML(event.files[0], pago.id).subscribe(resp => {
            this.uploadingXML = false;
            this.changePagoOnOrder(pago.id, resp['data'], 'xml');
            form.clear();
            console.log(this.order);
        }, err => {
            this.uploadingXML = false;
            console.error(err);
        });
    }

    private changePagoOnOrder(pagoID, newPago: Pago, campo: string) {
        for (let i = 0; i < this.order.pagos.length; i++) {
            // tslint:disable-next-line: triple-equals
            if (this.order.pagos[i].id == pagoID) {
                this.order.pagos[i][campo] = newPago[campo];
                break;
            }
        }
    }

    public showPDF(pagoID) {
        this.pagoService.getRecibo(pagoID).subscribe(pdf => {
            const file = new Blob([pdf], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }, err => console.error(err));
    }

    public showXML(pagoID) {
        this.pagoService.getXML(pagoID).subscribe(xml => {
            const file = new Blob([xml], { type: 'text/xml' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }, err => console.error(err));
    }

    public asignarNRecibo(nrecibo, pago) {
        if (!pago.noReciboEditable) {
            pago.noReciboEditable = true;
            return;
        }
        this.disabledNoReciboBtn = true;
        this.pagoService.setNumeroRecibo(nrecibo, pago.id).subscribe(resp => {
            this.disabledNoReciboBtn = false;
            this.changePagoOnOrder(pago.id, resp['data'], 'nrecibo');
            pago.noReciboEditable = false;
        }, err => {
            this.disabledNoReciboBtn = false;
            pago.noReciboEditable = false;
        });
    }

    public showFiscalPago(pago: Pago) {
        console.log(pago);
        this.fiscalSelected = pago.fiscal;
        this.fiscalSelected.display = true;
    }

    public cancelOrder() {
        this.confirmationService.confirm({
            message: '¿Estás seguro de que deseas cancelar la orden actual?',
            reject: () => {
                return false;
            },
            accept: () => {
                this.orderService.cancelOrder(this.order.id).subscribe( order => {
                    this.order = order['data'];
                    this.complementOrder();
                }, err => {
                    this.dialog = { header: 'Error', message: 'No se pudo realizar la operación.', display: true };
                    console.error( err );
                });
            }
        });
    }

    private totalAbonado(): number {
        let totalAbonado = 0;
        for (const pago of this.order.pagos) {
            if (pago.status === 'pagado') {
                totalAbonado += +pago.pago;
            }
        }
        return +totalAbonado;
    }

    private complementOrder() {
        this.order.pagado = this.totalAbonado();
        this.order.resto = this.order.total - this.order.pagado;
        this.order.moneda = this.order.dollar ? 'MXN' : 'USD';
    }

    showDialogUploadComprobante(pagoID) {
        this.pagoID = pagoID;
        this.visibleUploadFile = true;
    }

    cancelUploadFile(imgForm) {
        imgForm.clear();
        this.visibleUploadFile = false;
    }

    private getPayMethod() {
        let metodos = [];
        this.methodService.getMethods().subscribe(data => {
            metodos = data['data'];
            this.payMethods = metodos.filter(method => method.slug != 'donacion');
        });
    }

    cancelNewPago(form: NgForm) {
        this.selectedPago = '';
        this.visibleModalNewPago = false;
        form.reset();
    }

    public createPago(form: NgForm) {
        this.disabledCreatePagoBtn = true;
        this.pagoService.newPago(form.value).subscribe( pago => {
            this.disabledCreatePagoBtn = false;
            this.order.pagos.push(pago['data']);
            this.cancelNewPago(form);
        }, err => {
            this.disabledCreatePagoBtn = false;
            if (err.status === 400) {
                this.dialog.header = 'Importante',
                this.dialog.message = 'Debes subir el comprobante del pago anterior.',
                this.dialog.display = true;
            }
            this.cancelNewPago(form);
        });
    }

    showFechaPagoMolda(pagoID) {
        this.pagoID = pagoID;
        const pago = this.getPagoById(this.pagoID);
        console.log( pago.fecha_pago, new Date(pago.fecha_pago));
        if (pago.fecha_pago) {
            this.fechaPago = new Date(pago.fecha_pago);
        } else {
            this.fechaPago =  new Date();
        }
        this.visibleModalFechaPago = true;
    }

    private getPagoById(id): Pago {
        for (const pago of this.order.pagos) {
            if (pago.id == id) {
                return pago;
            }
        }
    }

    updateFechaPago() {
        this.sendingPayDate = true;
        this.pagoService.updateFechaPago(Date.parse(this.fechaPago.toString()), this.pagoID).subscribe(pago => {
            console.log(pago);
            this.sendingPayDate = false;
            this.changePagoOnOrder(this.pagoID, pago['data'], 'fecha_pago');
            this.cancelUpdateFechaPago();
        }, err => {
            console.error(err);
            this.sendingPayDate = false;
            this.cancelUpdateFechaPago();
        });
    }

    cancelUpdateFechaPago() {
        this.pagoID = '';
        this.visibleModalFechaPago = false;
    }

    editMontoRDA(pago) {
        pago.pagoEdit = true;
        pago.pagoAux = pago.pago;
    }

    cancelEditmontoRDA(pago) {
        if (pago.pagoAux) {
            pago.pago = pago.pagoAux;
        }
        pago.pagoEdit = false;
    }

    updateMontoRDA(pago) {
        this.dialogType = 'pagado';
        this.actionPagoID = pago.id;
        this.monto = pago.pago;
        if (pago.pagoAux) {
            this.disabledMontoBtn = true;
            this.enviarValidacion();
        } else {
            return;
        }
    }

    public updateFolio(pago: Pago) {
        this.disableFolioBtn = true;
        this.pagoService.updateFolio(pago).subscribe(response => {
            this.disableFolioBtn = false;
            this.changePagoOnOrder(pago.id, response['data'], 'folio_voucher');
        }, err => {
            console.error(err);
            this.disableFolioBtn = false;
        });
    }

}
