import { Component } from '@angular/core';
import {AppComponent} from './app.component';
import { AuthService } from './demo/service/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-sidebar',
    templateUrl: './app.sidebar.component.html'
})
export class AppSideBarComponent {

  constructor(public app: AppComponent, private auth: AuthService, private router: Router) {}

  logout() {
    this.auth.logout();
    this.router.navigate(['/login']);
  }

}
