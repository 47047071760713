import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Responsiva } from '../../Models/Responsiva';
import { ResponsivaService } from '../../service/responsiva.service';

@Component({
  selector: 'app-espacio-show',
  templateUrl: './espacio-show.component.html',
  styles: []
})
export class EspacioShowComponent implements OnInit {
  responsiva: Responsiva;

  constructor(private route: ActivatedRoute, private responsivaService: ResponsivaService) {
    this.responsiva = new Responsiva();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.responsivaService.getResponsiva(params['id']).subscribe( responsiva => {
        this.responsiva = responsiva['data'];
        console.log(this.responsiva);
      });
    });
  }

}
