import { Component, OnInit } from '@angular/core';
import { User } from '../../Models/User.model';
import { UserService } from '../../service/user.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styles: []
})
export class AccountComponent implements OnInit {
  user: User;
  cloneUser;

  constructor(private userService: UserService) {
    this.user = new User('', '', '', '');
  }

  ngOnInit() {
    this.userService.getUser().subscribe( user => {
      this.user = user['data'];
      this.cloneUser = { ...this.user };
      console.log(this.user);
    });
  }

  public updateUser() {
    if (!this.isUserAttributesChanged()) {
      console.error('Sin cambios');
      return;
    }
    this.userService.updateAdminAcount( this.user ).subscribe( user => {
      // tslint:disable-next-line: no-string-literal
      this.user = user['data'];
      this.cloneUser = {...this.user};
      console.log('Datos modificados');
    }, err => {
      console.error(err);
    });
  }

  private isUserAttributesChanged() {
    if (this.user.first_name !== this.cloneUser.first_name ||
    this.user.last_name !== this.cloneUser.last_name) {
      return true;
    }
    return false;
  }

  public updatePass() {
    if (!this.isPasswordValid()) {
      console.error('las contraseñas coinciden');
    }
    this.userService.updateAdminAcount( this.user ).subscribe( user => {
      // tslint:disable-next-line: no-string-literal
      this.user = user['data'];
      this.cloneUser = {...this.user};
      console.log('Datos modificados');
    }, err => {
      console.error(err);
    });
  }

  private isPasswordSetted() {
    return this.user.password || this.user.password_confirmation ? true : false;
  }

  private isPasswordValid() {
    return (this.isPasswordSetted() && this.user.password === this.user.password_confirmation) ? true : false;
  }

  public updateCorreo(form) {
    if (!this.isEmailValid()) {
      console.error('los no correos coinciden');
    }
    this.userService.updateAdminAcount( this.user ).subscribe( user => {
      // tslint:disable-next-line: no-string-literal
      this.user = user['data'];
      this.cloneUser = {...this.user};
      console.log('Datos modificados');
    }, err => {
      console.error(err);
    });
  }

  private isEmailValid() {
    return this.user.correo && this.user.correo === this.user.correo_confirmation ? true : false;
  }

}
