export class Stand {
    id: number;
    name: string;
    size: string;
    dimention: string;
    price: number;
    estado: string;

    public constructor(id: number, estado: string) {
        this.id = id;
        this.estado = estado;
    }
}
